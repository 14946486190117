<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" v-b-modal.new-modal>ثبت هدیه</b-button>
    <b-card title="هدایا" class="mt-3">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">عنوان</label>
                  <b-form-input id="title" v-model="searchParams.title"></b-form-input>
                </div>
              </div>

              <div class="col-md-6">
                <b-form-group label=" قیمت (تومان)" label-for="price">
                  <b-form-input id="price" v-model="search_price"></b-form-input>
                </b-form-group>
              </div>
    
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">
        <template v-slot:cell(created_at)="data">{{ data.item.created_at | persianDate }}</template>

        <template v-slot:cell(price)="data">{{ numberFormat(data.item.price) }}</template>

        <template v-slot:cell(image)="data">
          <a :href="data.item.image" target="_blank">
            <img :src="data.item.image" class="img-thumbnail" width="50">
          </a>
        </template>

        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)">
            <i class="fa fa-edit"></i>
          </button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)">
            <i class="fa fa-close"></i>
          </button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>

    <b-modal id="new-modal" title="ثبت هدیه" hide-footer>
      <div>
        <form @submit.prevent="newItem" id="new-item">
          <b-form-group>
            <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="title" name="title" data-required :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="price"> قیمت ( تومان ) <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="price" title="price" data-required v-model="price" :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="description">توضیحات <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="description" name="description" data-required :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group label="بارگذاری تصویر" label-for="image">
            <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
          </b-form-group>

          <div class="clearfix">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش هدیه" hide-footer>
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <b-form-group>
            <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="title" name="title" v-model="edit.title" data-required :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="price"> قیمت ( تومان ) <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="edit-price" title="price" v-model="price" data-required :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="description">توضیحات <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="description" name="description" v-model="edit.description" data-required :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group label="بارگذاری تصویر" label-for="image">
            <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
          </b-form-group>
          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="PUT" name="_method">
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import mixins from './mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/gift',
        title: 'هدایا',
        gifts: [],
        selected_filter_status: '',
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'title',
            label: 'عنوان'
          },
          {
            key: 'price',
            label: 'قیمت (تومان)'
          },
          {
            key: 'image',
            label: 'تصویر'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ]
      }
    },
    created() {
      this.loadItems()
      this.searchParams = {
        title:'',
        price:''
      }
    },
    methods: {
      editItem(index) {
        this.edit = {}
        let item = window.clone(this.items.data.data[index])
        item.index = index
        this.edit = item
        this.price = this.edit.price
        this.$root.$emit('bv::show::modal', 'edit-modal')
      },
      searchItems(page = 1) {
            this.disabled = true
            this.search = true
            let org_price = this.search_price <= 999 ? this.search_price : this.search_price.replace(/\D/g, "");
            this.searchParams.price = org_price
    
            this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page , {params: this.searchParams})
              .then(response => {
                this.search = true
                this.items = response.data
              })
              .catch(error => {
                this.$root.error_notification(error)
              })
              .finally(() => {
                this.disabled = false
              })
          },
    }
  }
</script>